var weekdayString = new Array(7);
weekdayString[0] = "Pazar";
weekdayString[1] = "Pazartesi";
weekdayString[2] = "Salı";
weekdayString[3] = "Çarşamba";
weekdayString[4] = "Perşembe";
weekdayString[5] = "Cuma";
weekdayString[6] = "Cumartesi";

var monthString = new Array();
monthString[0] = "Ocak";
monthString[1] = "Şubat";
monthString[2] = "Mart";
monthString[3] = "Nisan";
monthString[4] = "Mayıs";
monthString[5] = "Haziran";
monthString[6] = "Temmuz";
monthString[7] = "Ağustos";
monthString[8] = "Eylül";
monthString[9] = "Ekim";
monthString[10] = "Kasım";
monthString[11] = "Aralık";

var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();
var isSticky = false;
var saveTimeout;
var commonModule = (function () {
    var mobileMenu, mobileSearchInit, initIsMobile, calendarInit, photoGalleryInit, initSlider, initIcheck,
        initStickyHeader, initAliciBilgileri;
    var screenWidth = $(window).width(),
        screenHeight = $(window).height(),
        defaultSelector = {
            menu: $('nav#my-menu'),
            mobileSearch: $('.mobileSearch'),
            sendAreaSelector: $('#sendArea'),
            selectInit: $('.select2Auto'),
            calendarInit: $('.container.calendar  .calendarContainer'),
            datePickerInit: $('.product .dataPicker'),
            masterImagesSlider: $('.masterImageWrapper .masterImage'),
            last: ''
        },
        defaultVariable = {
            fisrt: '',
            last: ''
        }
    ;
    mobileMenu = function (selector) {
        selector = (typeof selector !== 'undefined') ? selector : defaultSelector.menu;
        selector.mmenu();
    };
    mobileSearchInit = function (selector) {
        selector = (typeof selector !== 'undefined') ? selector : defaultSelector.mobileSearch;

        selector.click(function (e) {
            e.preventDefault();
            $("form.search").closest("div").toggleClass("hidden-sm-down");
        });

    };
    calendarInit = function (selector) {
        selector = (typeof selector !== 'undefined') ? selector : defaultSelector.calendarInit;

        selector.click(function () {
            var thisis = $(this);

            var data = new Object({});
            data.action = 1;
            data.date = thisis.data("date");
            data.fullDate = thisis.data("full-date");
            if (!thisis.hasClass("disabled") && !thisis.hasClass("active")) {
                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: data,
                    dataType: 'html',
                    cache: false,
                    success: function (result) {
                        selector.removeClass('active').closest('.col-3').removeClass('active');
                        selector.closest('.col-3').find('.time').html(result);
                        thisis.addClass('active').closest('.col-3').addClass('active');
                        $('.container.calendar .col-3:not(.active) .time select').remove();
                        console.log(data.fullDate);
                        $('#postDate').val(data.fullDate);
                        if ($("body").hasClass("mobile")) {
                            console.log("isMobile");
                            $("#postTime option:not([value]").remove();
                            setTimeout(function () {
                                commonModule.initSelect2($("#postTime"));
                            }, 300);
                        }
                        $('.col-3.active #sendingTime').change(function () {
                            var thisis = $(this);
                            $('#postTime').val(thisis.val());
                        })
                    },
                    error: function (jqXHR, exception) {
                        if (DEBUG) {
                            console.log(exception);
                        }
                    }
                });
            }
            commonModule.formExtraHiddenInput($('form#addToCart'), 'date', '2');

        });

    };
    initTopFormSubmit = function (selector) {
        selector = (typeof selector !== 'undefined') ? selector : defaultSelector.searchTopForm;

        selector.submit(function (e) {
            e.preventDefault();
            window.location.href = '/Arama/' + selector.find("#q").val();
        });
        /*
         selector.submit(function (e) {
         e.preventDefault();
         //$(this).unbind('submit').submit()
         });*/
    };
    photoGalleryInit = function (selector) {

        selector = (typeof selector !== 'undefined') ? selector : $("ul#otherPic > li");

        selector.click(function () {
            var thisis = $(this);
            var imgSrc = thisis.find("img").attr("src");
            selector.removeClass("active");
            thisis.addClass("active");
            $("section.product.container img.masterImage").attr("src", imgSrc);
        });

    };
    initIsMobile = function (selector) {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) {
            $('body').addClass('windowsPhone mobile');
            return true;
        }
        else if (/android/i.test(userAgent)) {
            $('body').addClass('android mobile');
            return true;
        }
        else if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
            $('body').addClass('ios mobile');
            return true;
        }
        else if (/iPad/.test(userAgent) && !window.MSStream) {
            $('body').addClass('ipad ios mobile');
            return true;
        }
        else {
            return false;
        }


    };
    initSlider = function (selector) {
        selector = (typeof selector !== 'undefined') ? selector : $(".sliderInit");
        selector.owlCarousel({
            items: 4,
            loop: true,
            nav: true,
            navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
            responsiveClass: true,
            lazyLoad: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                600: {
                    items: 3,
                    nav: false
                },
                1000: {
                    items: 4,
                    nav: true,
                    loop: false
                }
            }
        });


    };
    postCardMessageSave = function (thisis) {


        var basketId = thisis.data('basketid');
        var messageName = thisis.find('input.messageName').val();
        var message = thisis.find('textarea.message').val();
        var kartIsimGizle = 0;
        if (thisis.find('.hiddenMessageName').is(':checked')) {
            kartIsimGizle = 1;
        }
        /////
        $.ajax({
            type: 'POST',
            url: '/api/ajax.php',
            data: {
                action: 7,
                basketId: basketId,
                messageName: messageName,
                message: message,
                kartIsimGizle: kartIsimGizle

            },
            dataType: 'html',
            success: function (data) {
                console.log(data);
                commonModule.initPostCardInformation();
            },
            error: function (data) {
                console.log("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
            },
        });

    };
    initStickyHeader = function (thisis) {
        $(window).scroll(function () {
            var windowScrollPosition = $(this).scrollTop();
            var headerHeight = $('header').height();
            if (windowScrollPosition >= headerHeight) {
                $('body').addClass("fixedHeader");
                isSticky = true;

            }
            else {
                $('body').removeClass("fixedHeader");
                isSticky = false;
            }
        });
    };
    initFaturaGoster = function () {
        $("a.showFatura").click(function (e) {
            e.preventDefault();
            var thisis = $(this);
            var status = thisis.data("status");
            if (status) {
                $(".fatura").addClass("hidden-xl-down");
                thisis.find("i.fa").addClass("fa-angle-down").removeClass("fa-angle-up");

                thisis.data("status", false);
            } else {
                thisis.data("status", true);
                thisis.find("i.fa").removeClass("fa-angle-down").addClass("fa-angle-up");
                $(".fatura").removeClass("hidden-xl-down");
            }
        });
    };
    initFaturaSirket = function () {
        $(".faturaTipi input[name='faturaTipi']").change(function (e) {
            var thisis = $(this);
            var value = thisis.val();
            if (value == 1) {
                $(".sirket").addClass("hidden-xl-down");
            } else {
                $(".sirket").removeClass("hidden-xl-down");
            }
        });
    };

    return {
        screenWidth: $(window).width(),
        screenHeight: $(window).height(),
        isMobile: initIsMobile(),
        initMasterImagesSlider: function (selector) {
            selector = (typeof selector !== 'undefined') ? selector : defaultSelector.masterImagesSlider;
            var masterImagesSlider = selector.owlCarousel({
                items: 1,
                loop: true,
                nav: true,
                lazyLoad: true,
                autoplay: true,
                autoplayTimeout: 3000,
                autoplayHoverPause: true,
                navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],

            });
            masterImagesSlider.on('changed.owl.carousel', function (e) {
                console.log(e.item.index);
                $("ul#otherPic li").removeClass("active");
                $("ul#otherPic li:nth-child(" + (e.item.index - 1) + ")").addClass("active");
            });

            $("ul#otherPic li").click(function () {
                var thisis = $(this);
                var index = thisis.index();
                $("ul#otherPic li").removeClass("active");
                thisis.addClass("active");
                masterImagesSlider.trigger('to.owl.carousel', [index, 300, true]);
                console.log(index);
            });


        },
        masterImagesSliderNext: function (index) {

            masterImagesSlider.trigger('to.owl.carousel', index);
            return true

        },
        initSelect2: function (selector) {
            selector = (typeof selector !== 'undefined') ? selector : defaultSelector.selectInit;


            selector.each(function (index) {
                var thisis = $(this);
                var placeholder = thisis.attr('date-placeholder');
                console.log("!" + placeholder);
                var _select2Search;
                if (thisis.data("search") == true) {
                    _select2Search = "";
                } else {
                    _select2Search = "Infinity";
                }
                thisis.select2({
                    minimumResultsForSearch: _select2Search,
                    placeholder: placeholder

                });
                thisis.on('change', function (e) {
                    var theSelection = $(this).val();
                    thisis.data('val', theSelection);
                });
            });


        },
        initDatePickerProductDetail: function (selector) {
            selector = (typeof selector !== 'undefined') ? selector : defaultSelector.datePickerInit;
            selector.datepicker({
                container: 'body',
                autoclose: true,
                language: 'tr',
                startDate: new Date(),
                endDate: '+1m'
            }).on("changeDate", function (e) {

                var dateMaster = e.date;
                var year = dateMaster.getFullYear();
                var month = dateMaster.getMonth();
                var day = dateMaster.getDay();
                var date = dateMaster.getDate();
                date = date.toString();
                monthDobule = month.toString();
                if (date.length == 1) {
                    date = '0' + date;
                }
                if (monthDobule.length == 1) {
                    monthDobule = '0' + monthDobule;
                }


                if (date.length == 1) {
                    date = '0' + date;
                }
                $('.container.calendar .col-3.active').removeClass("active").find(".calendarContainer").removeClass("active");
                $('.customCalendar').addClass("active").find(".calendarContainer").addClass("active");
                $('.customCalendar .area-1').html(date + ' ' + monthString[month]);
                $('.customCalendar .area-2').html(weekdayString[day]);
                $('.container.calendar .col-3:not(.active) .time select').remove();

                var data = new Object({});
                data.action = 1;
                data.date = date + '-' + monthDobule;
                data.fullDate = year + '-' + monthDobule + '-' + date;
                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: data,
                    dataType: 'html',
                    cache: false,
                    success: function (result) {
                        selector.removeClass('active').closest('.col-3').removeClass('active');
                        selector.closest('.col-3').find('.time').html(result);
                        $('.customCalendar').addClass('active').closest('.col-3').addClass('active');
                        $('.container.calendar .col-3:not(.active) .time select').remove();
                        console.log(data.fullDate);
                        $('#postDate').val(data.fullDate);
                        //$('#postDate').after('<input type="text" name="postTime" id="postTime" data-validation-engine="validate[required]" data-errormessage-value-missing="Lütfen teslimat saati seçiniz !">');
                        setTimeout(function () {
                            commonModule.initSelect2($("#postTime"));
                        }, 300);
                        //setTimeout(function(){ $('#addToCart').validationEngine(); }, 3000);


                        /*
                         $('.col-3.active #sendingTime').change(function () {
                         var thisis = $(this);
                         $('#postTime').val(thisis.val());
                         })
                         */

                    },
                    error: function (jqXHR, exception) {
                        if (DEBUG) {
                            console.log(exception);
                        }
                    }
                });

            });


        },
        getUrlParameters: function (parameter) {

            var sURL = window.document.URL.toString();
            if (sURL.indexOf('?') > 0) {
                var arrParams = sURL.split('?');
                var arrURLParams = arrParams[1].split('&');
                var arrParamNames = new Array(arrURLParams.length);
                var arrParamValues = new Array(arrURLParams.length);

                var i = 0;
                for (i = 0; i < arrURLParams.length; i++) {
                    var sParam = arrURLParams[i].split('=');
                    arrParamNames[i] = sParam[0];
                    if (sParam[1] !== '')
                        arrParamValues[i] = unescape(sParam[1]);
                    else
                        arrParamValues[i] = 'No Value';
                }

                for (i = 0; i < arrURLParams.length; i++) {
                    if (arrParamNames[i] == parameter) {
                        //alert('Parameter:' + arrParamValues[i]);
                        return arrParamValues[i];
                    }
                }
                return 'No Parameters Found';
            }

        },
        changeURLParameters: function (key, value) {
            var lastUrl;
            var uri = window.location.href;
            var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
            var separator = uri.indexOf('?') !== -1 ? '&' : '?';
            if (uri.match(re)) {
                lastUrl = uri.replace(re, '$1' + key + '=' + value + '$2');
            }
            else {
                lastUrl = uri + separator + key + '=' + value;
            }
            window.history.pushState('', '', lastUrl);

        },
        removeURLParameters: function (key) {

            url = decodeURI(window.location.href).split('?');
            path = url.length == 1 ? '' : url[1];
            path = path.replace(new RegExp('&?' + key + '\\[\\d*\\]=[\\w]+', 'g'), '');
            path = path.replace(new RegExp('&?' + key + '=[\\w]+', 'g'), '');
            path = path.replace(/^&/, '');
            var urlEnd = url[0] + (path.length ? '?' + path : '');
            window.history.pushState('', '', urlEnd);

        },
        setCookie: function (name, value, expireDays) {
            var d = new Date();
            d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
            var expires = 'expires=' + d.toUTCString();
            document.cookie = name + '=' + value + ';' + expires + ';path=/';
        },
        getCookie: function (name) {
            name = name + '=';
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return '';
        },
        arrayRemoveItem: function (array, item) {
            for (var i in array) {
                if (array[i] == item) {
                    array.splice(i, 1);
                    break;
                }
            }

        },
        createCustomEvent: function (name) {

            var eventCreate = new CustomEvent(name);
            document.body.dispatchEvent(eventCreate);

        },
        cleanArray: function (a, removeNull) {
            var i, l, temp = [];
            l = a.length;
            if (removeNull) {
                for (i = 0; i < l; i++) {
                    if (a[i] !== undefined && a[i] !== null) {
                        temp.push(a[i]);
                    }
                }
            } else {
                for (i = 0; i < l; i++) {
                    if (a[i] !== undefined) {
                        temp.push(a[i]);
                    }
                }
            }
            a.length = 0;
            l = temp.length;
            for (i = 0; i < l; i++) {
                a[i] = temp[i];
            }
            temp.length = 0;
            return a;


        },
        cleanObject: function (obj) {
            for (var propName in obj) {
                if (obj[propName] === null || obj[propName] === undefined) {
                    delete obj[propName];
                }
            }

        },
        listenCustomEvent: function (name, run) {

            document.body.addEventListener(name, run, false);

            // Örnek Kod commonModule.listenCustomEvent('eventtest', commonModule.eventTestAlert);


            /*

             eventTest = function () {

             commonModule.listenCustomEvent('eventtest', commonModule.eventTestAlert);

             function doSomething(e) {
             alert('Event is called: ' + e.type);
             }

             };


             */

        },
        cnsl: function (message, type) {
            if (DEBUG) {
                if (type === 0 || type === undefined || type === '') {
                    console.log(message);
                } else if (type === 1) {
                    console.warn(message);
                } else if (type === 2) {
                    console.info(message);
                } else {
                    console.log(message);
                }
            }
        },
        renameObjKey: function (obj, fromKey, toKey) {

            obj[toKey] = obj[fromKey];
            delete obj[fromKey];

        },
        createOverlay: function (opacity) {

            $('body').append('<div class="bodyOverlay"></div>');

        },
        removeOverlay: function (opacity) {

            $('body > .bodyOverlay').remove();

        },
        initIcheck: function (selector) {

            $('input').iCheck({
                checkboxClass: 'icheckbox_flat-green',
                radioClass: 'iradio_flat-green'
            });

        },
        initExtraProductAddButton: function (selector) {


            $(".product-add-button").click(function () {
                var thisis = $(this);

                console.log(thisis);
            });

        },
        formExtraHiddenInput: function (selector, name, val) {
            selector = (typeof selector !== 'undefined') ? selector : $('form#addToCart');
            if (selector.find('[name="' + name + '"]').length) {
                selector.find('[name="' + name + '"]').val(val);
            } else {
                $('<input>').attr({
                    type: 'hidden',
                    id: name,
                    name: name,
                    value: val
                }).appendTo(selector);
            }
        },

        initAddToCartSubmit: function (selector) {
            selector = (typeof selector !== 'undefined') ? selector : $('form#addToCart');
            selector.submit(function (e) {
                e.preventDefault();

                if (!selector.validationEngine('validate')) {
                    return false;
                }
                var productCode = $("#productCode").val();
                var postRegion = $("#postRegion").val();
                var postDate = $("#postDate").val();
                var postTime = $("#postTime").val();
                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: {
                        action: 2,
                        productCode: productCode,
                        postRegion: postRegion,
                        postDate: postDate,
                        postTime: postTime
                    },
                    dataType: 'html',
                    success: function (data) {
                        console.log(data);
                        window.location = window.location.origin + '/teslimat-adresi';
                    },
                    error: function (data) {
                        alert("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
                    },
                });
            });


        },
        initSaveAliciBilgileriSubmit: function () {
            selector = $('#aliciBilgileriForm');

            selector.submit(function (e) {

                if (!$('#aliciBilgileriForm').validationEngine('validate')) {
                    return false;
                }


            });


        },
        initSaveCardMessageSubmit: function () {
            selector = $('#cardMessageForm');

            selector.submit(function (e) {

                if (!$('#cardMessageForm').validationEngine('validate')) {
                    return false;
                }


            });


        },
        initCustomerInfoSaveSubmit: function () {
            selector = $('#customerInfoSaveForm');

            selector.submit(function (e) {

                if (!$('#customerInfoSaveForm').validationEngine('validate')) {
                    return false;
                }


            });


        },
        initBasketInfo: function (selector) {
            selector = (typeof selector !== 'undefined') ? selector : $('.initBasketInfo');

            selector.each(function () {
                var thisis = $(this);

                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: {
                        action: 3,
                        basketId: thisis.data('basketid')
                    },
                    dataType: 'html',
                    success: function (data) {
                        thisis.html(data);
                    },
                    error: function (data) {
                        console.log("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
                    },
                });

            });


        },

        initOrderInfo: function (selector) {
            selector = (typeof selector !== 'undefined') ? selector : $('.initOrderInfo');

            selector.each(function () {
                var thisis = $(this);

                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: {
                        action: 4,
                    },
                    dataType: 'html',
                    success: function (data) {
                        if (data === "false") {
                            //window.location = window.location.origin;
                            console.log("Basket Empty");
                            $(".emptyBasket").removeClass("hidden-xl-down");
                            $(".basketInfo").addClass("hidden-xl-down");
                            $(".backButtonArea").addClass("hidden-xl-down");
                        } else {
                            thisis.html(data);
                            $(".grandTotalReturn").html($(".grandTotal").data("price"));

                            commonModule.initOrderInfoRemove();
                        }

                    },
                    error: function (data) {
                        console.log("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
                    },
                });

            });


        },
        initExtraProductInformation: function () {
            selector = (typeof selector !== 'undefined') ? selector : $('.initExtraProductInfo');

            selector.each(function () {
                var thisis = $(this);

                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: {
                        action: 6,
                        basketId: thisis.data('basketid')

                    },
                    dataType: 'html',
                    success: function (data) {
                        if (data == "") {
                            thisis.find('.extraProductInformation').show();
                            thisis.find('.extraProductionListArea').html("").hide();
                        } else {
                            thisis.find('.extraProductInformation').hide();
                            thisis.find('.extraProductionListArea').html(data).show();
                        }
                    },
                    error: function (data) {
                        console.log("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
                    },
                });

            });


        },
        initPostCardInformation: function () {
            selector = $('.initPostCardInfo');

            selector.each(function () {
                var thisis = $(this);
                console.log(thisis);
                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: {
                        action: 8,
                        basketId: thisis.data('basketid')
                    },
                    dataType: 'html',
                    success: function (data) {
                        if (data == "") {
                            thisis.find('.postCardInformation').show();
                            thisis.find('.postCardArea').html("").hide();
                        } else {
                            thisis.find('.postCardInformation').hide();
                            thisis.find('.postCardArea').html(data).show();
                        }
                    },
                    error: function (data) {
                        console.log("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
                    },
                });

            });


        },
        initAddExtraProduct: function () {
            selectorAdd = $('section.extraProduct .product-add-button-wrapper .add, section.messageCard .product-add-button-wrapper .add');
            selectorIncrease = $('section.extraProduct .product-add-button-wrapper .increase, section.messageCard .product-add-button-wrapper .increase');
            selectorDecrease = $('section.extraProduct .product-add-button-wrapper .decrease, section.messageCard .product-add-button-wrapper .decrease');
            selectorValue = $('section.extraProduct .product-add-button-wrapper .counter__count, section.messageCard .product-add-button-wrapper .counter__count');
            selectorPostCard = $('section.messageCard .product-add-button-wrapper .addPostCard');

            selectorAdd.click(function () {
                var thisis = $(this);
                thisis.css('display', 'none');
                thisis.closest(".product-add-button-wrapper").find(".counter").css('display', 'block');
                thisis.closest(".product-add-button-wrapper").find(".counter__count").val(1).trigger('change');
            });


            selectorIncrease.click(function () {
                var thisis = $(this);
                var counter = thisis.closest(".product-add-button-wrapper").find(".counter__count");
                counter.get(0).value++;
                counter.trigger('change');

            });
            selectorDecrease.click(function () {
                var thisis = $(this);
                var counter = thisis.closest(".product-add-button-wrapper").find(".counter__count");

                if (counter.val() == 1) {
                    counter.closest('.counter').css('display', 'none');
                    thisis.closest(".product-add-button-wrapper").find(".add").css('display', 'inline-block');
                }
                counter.get(0).value--;
                counter.trigger('change');

            });

            selectorValue.each(function () {
                var thisis = $(this);
                if (thisis.val() > 0) {
                    thisis.closest(".product-add-button-wrapper").find(".add").css('display', 'none');

                    thisis.closest(".product-add-button-wrapper").find(".counter").css('display', 'block');
                }
            });

            selectorValue.change(function () {
                var thisis = $(this);
                console.log(thisis);
                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: {
                        action: 5,
                        productId: thisis.data('productid'),
                        count: thisis.val(),
                        masterProduct: thisis.data('masterproduct'),
                        basket: thisis.data('basket'),
                    },
                    dataType: 'html',
                    success: function (data) {
                        commonModule.initOrderInfo();
                        commonModule.initExtraProductInformation();
                    },
                    error: function (data) {
                        console.log("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
                    },
                });

            });
            $("select.messageSample").change(function () {
                var thisis = $(this);
                var _val = thisis.val();
                thisis.closest("form").find(".leftArrow > i").removeClass("hidden-xl-down");
                thisis.closest("form").find(".rightArrow > i").removeClass("hidden-xl-down");
                thisis.closest("form").attr("data-categories", _val);
                thisis.closest("form").attr("data-messageId", "0");
                thisis.closest("form").find(".message").val(messageList[_val][0]);
                delay(function () {
                    postCardMessageSave(thisis.closest("form"));
                }, 500);
            });
            $(".leftArrow").click(function () {
                console.log("!");
                var thisis = $(this);
                var _val = thisis.closest("form").attr("data-categories");
                var _messageId = thisis.closest("form").attr("data-messageId");
                _messageId = parseInt(_messageId) - 1;
                if (_messageId <= -1) {
                    _messageId = messageList[_val].length - 1;
                }
                thisis.closest("form").find(".message").val(messageList[_val][_messageId]);
                thisis.closest("form").attr("data-messageId", _messageId);
                postCardMessageSave(thisis.closest("form"));

            });
            $(".rightArrow").click(function () {
                var thisis = $(this);
                var _val = thisis.closest("form").attr("data-categories");
                var _messageId = thisis.closest("form").attr("data-messageId");
                _messageId = parseInt(_messageId) + 1;

                if (_messageId > messageList[_val].length - 1) {
                    _messageId = 0;
                }
                thisis.closest("form").find(".message").val(messageList[_val][_messageId]);
                thisis.closest("form").attr("data-messageId", _messageId);
                postCardMessageSave(thisis.closest("form"));

            });
            selectorPostCard.change(function () {
                var thisis = $(this);
                console.log(thisis);
                var currentVal;
                if (thisis.val() == 'on') {
                    currentVal = 1;
                } else {
                    currentVal = 0;
                }
                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: {
                        action: 5,
                        productId: thisis.data('productid'),
                        count: currentVal,
                        masterProduct: thisis.data('masterproduct'),
                        basket: thisis.data('basket'),
                    },
                    dataType: 'html',
                    success: function (data) {
                        commonModule.initOrderInfo();
                        commonModule.initExtraProductInformation();
                    },
                    error: function (data) {
                        console.log("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
                    },
                });

            });

        },
        initPostCardMessage: function () {
            $('form.messageArea .messageName').keyup(function (e) {
                var thisis = $(this).closest('form.messageArea');
                delay(function () {
                    postCardMessageSave(thisis);
                }, 500);
            });
            $('form.messageArea .message').keyup(function (e) {
                var thisis = $(this).closest('form.messageArea');
                delay(function () {
                    postCardMessageSave(thisis);
                }, 500);
            });
            $('form.messageArea .message').change(function (e) {
                var thisis = $(this).closest('form.messageArea');
                delay(function () {
                    postCardMessageSave(thisis);
                }, 500);
            });

            $(".hiddenMessageName").each(function (index) {
                if ($(this).is(':checked')) {
                    $(this).closest('form.messageArea').find(".messageName").val('').prop('disabled', true).addClass("hidden-xl-down");
                }
            });
            /*
            if ($(".hiddenMessageName").is(':checked')) {

                    $(this).closest('form.messageArea').find(".messageName").val('').prop('disabled', true).addClass("hidden-xl-down");
              }
              */
            $('form.messageArea .hiddenMessageName').on('ifChanged', function (e) {
                var thisis = $(this);
                if (thisis.is(':checked')) {
                    thisis.closest('form.messageArea').find(".messageName").val('').prop('disabled', true).addClass("hidden-xl-down");
                } else {
                    thisis.closest('form.messageArea').find(".messageName").prop('disabled', false).removeClass("hidden-xl-down");
                }
                delay(function () {
                    postCardMessageSave(thisis.closest('form.messageArea'));
                }, 500);
            });
            $('form.messageArea #hiddenMessageName').change(function (e) {

            });
        },
        initAliciBilgileri: function () {

            var postData = function (form) {

                var data = new Object();
                data['action'] = 10;
                data['basketid'] = form.closest('.basketContainer').data('basketid');
                data['data1'] = form.find('.aliciAdi').val();
                data['data2'] = form.find('.aliciTelefon').val();
                data['data3'] = form.find('.aliciYer').val();
                data['data4'] = form.find('.aliciYer2').val();
                data['data5'] = form.find('.aliciAdres').val();
                //form.remove();
                $.ajax({
                    type: 'POST',
                    url: '/api/ajax.php',
                    data: data,
                    dataType: 'html',
                    success: function (data) {

                    },
                    error: function (data) {
                        console.log("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
                    },
                });
            };


            $("#sendArea").change(function () {
                var thisis = $(this);
                if (thisis.val() == 2) {
                    thisis.closest(".form-wrapper").find(".aliciYer2").attr("placeholder", "İşyeri veya Şirket İsmi").val('').removeClass("hidden-xl-down");
                } else if (thisis.val() == 3) {
                    thisis.closest(".form-wrapper").find(".aliciYer2").attr("placeholder", "Okul İsmi").val('').removeClass("hidden-xl-down");

                } else if (thisis.val() == 4) {
                    thisis.closest(".form-wrapper").find(".aliciYer2").attr("placeholder", "Hastane İsmi").val('').removeClass("hidden-xl-down");

                } else {
                    thisis.closest(".form-wrapper").find(".aliciYer2").attr("placeholder", "").val('').addClass("hidden-xl-down");
                }
            });


            $(".recipient input, .recipient textarea ").keydown(function (e) {
                var thisis = $(this).closest(".form-wrapper");

                clearTimeout(saveTimeout);
                saveTimeout = setTimeout(function () {
                    postData(thisis);

                }, 500);


            });
            $(".recipient select").change(function (e) {
                var thisis = $(this).closest(".form-wrapper");
                postData(thisis);
            });
        },
        initGonderenBilgileri: function () {

            var postData = function () {

                var data = new Object();
                var form = $("section.customerInfo");
                data['action'] = 11;
                data['email'] = form.find('#email').val();
                data['adi'] = form.find('#adsoyad').val();
                data['tel'] = form.find('#tel').val();
                data['sehir'] = form.find('#sehir').val();
                data['semt'] = form.find('#semt').val();
                data['sirket'] = form.find('#sirket').val();
                data['vergiDairesi'] = form.find('#vergiDairesi').val();
                data['vergiNumarasi'] = form.find('#vergiNumarasi').val();
                //form.remove();
                if (data['email'] !== null && data['email'] !== '') {

                    $.ajax({
                        type: 'POST',
                        url: '/api/ajax.php',
                        data: data,
                        dataType: 'html',
                        success: function (data) {
                            console.log(data);
                        },
                        error: function (data) {
                            console.log("Bir hata oluştu lütfen daha sonra tekrar deneyiniz !");
                        },
                    });
                }
            }
            //postData();

            $(".customerInfoSave input").change(function (e) {
                clearTimeout(saveTimeout);
                saveTimeout = setTimeout(function () {
                    postData();
                }, 500);
            });
            $(".customerInfoSave select").change(function (e) {
                postData();
            });


        },
        initBackToTop: function () {
            var offset = 220;
            var duration = 500;
            $('body').append('<a id="backToTop"><i class="fa fa-chevron-right"></i></a>');

            $(window).scroll(function () {
                if ($(this).scrollTop() > offset) {
                    $('#backToTop').fadeIn(duration);
                } else {
                    $('#backToTop').fadeOut(duration);
                }
            });

            $('#backToTop').click(function (event) {
                event.preventDefault();
                $('html, body').animate({scrollTop: 0}, duration);
                return false;
            });
        },
        initOrderInfoRemove: function () {

            $(".initOrderInfo a.remove").click(function () {
                var thisis = $(this);
                if (confirm('Sepetten çıkarmak istediğine emin misiniz?')) {
                    var data = new Object({});
                    data.action = 9;
                    data.basketid = thisis.data("basketid");
                    $.ajax({
                        type: 'POST',
                        url: '/api/ajax.php',
                        data: data,
                        dataType: 'html',
                        cache: false,
                        success: function (result) {
                            commonModule.initOrderInfo();
                            $("*[data-basketid='" + data.basketid + "']").remove();

                        },
                        error: function (jqXHR, exception) {
                            if (DEBUG) {
                                console.log(exception);
                            }
                        }
                    });
                }


            });

        },
        initCCPayment: function () {

            $(".payCC").click(function () {

                if (!$('#customerInfoSaveForm').validationEngine('validate')) {
                    return false;
                }
                var thisis = $(this);
                $("#Yukleniyor").show();
                $(".payCC").hide();
                $(".paymentTypeArea").hide();
                $(".payButton").hide();
                $(".returnMessage").removeClass("hidden-xl-down");
                $(".returnMessageShow").text("Ödemeniz Alınıyor...");
                $(".paymentCC").addClass("hidden-xl-down");

                var data = new Object({});
                data.action = 12;
                data.cardnumber = $('#ccNumber').val();
                data.cardexpiredatemonth = $('.ccExpireMonth').val();
                data.cardexpiredateyear = $('.ccExpireYear').val();
                data.cardcvv2 = $('#ccCVV').val();

                $.ajax({
                    type: "POST",
                    url: '/api/ajax.php',
                    data: data,
                    success: function (returnData) {

                        console.log(returnData);

                        if (returnData.SonucKod == 00) {
                            $(".container.orderSuccess .orderId").text(returnData.SiparisNo);

                            $(".container.orderSuccess .payCC").removeClass("hidden-xl-down");

                            $(".container.orderSuccess").removeClass("hidden-xl-down");
                            $(".container.payment").addClass("hidden-xl-down");
                            $(".returnMessageShow").addClass("hidden-xl-down");

                        }
                        else {
                            $(".returnMessageShow").append(returnData.Durum);

                            $("#Yukleniyor").hide();
                            $("#OdemeYap").show();
                        }


                    }, dataType: "json"
                });
            });

        },
        initBankPayment: function () {


            $(".paymentBank").click(function () {
                if (!$('#customerInfoSaveForm').validationEngine('validate')) {
                    return false;
                }
                var thisis = $(this);


                var data = new Object({});
                data.action = 13;


                $.ajax({
                    type: "POST",
                    url: '/api/ajax.php',
                    data: data,
                    success: function (returnData) {

                        console.log(returnData);

                        if (returnData) {
                            $(".container.orderSuccess .orderId").text(returnData);
                            $(".container.orderSuccess .payBank").removeClass("hidden-xl-down");

                            $(".container.orderSuccess").removeClass("hidden-xl-down");

                            $(".container.payment").addClass("hidden-xl-down");
                            $(".paymentArea").addClass("hidden-xl-down");
                            $(".basketInfo").addClass("hidden-xl-down");
                            $('html, body').animate({
                                scrollTop: $("body").offset().top
                            }, 500);

                        }
                        else {
                            alert("Bir Hata Oluştu");
                        }


                    }, dataType: "json"
                });
            });
        },
        initPaymentType: function () {

            $(".paymentType").click(function (e) {
                e.preventDefault();
                $(".paymentType.active").removeClass("active");
                var thisis = $(this);
                var paymentType = thisis.data('type');
                thisis.addClass("active");
                if (paymentType == "cc") {
                    $(".paymentBank").addClass("hidden-xl-down");
                    $(".paymentCC").removeClass("hidden-xl-down");
                }
                else {
                    $(".paymentCC").addClass("hidden-xl-down");
                    $(".paymentBank").removeClass("hidden-xl-down");
                }

            });

        },
        initBillingType: function () {

            $('input').on('ifChecked', function(event){
                console.log(event.currentTarget.id);
                if (event.currentTarget.id=='faturaTipiSirket') {
                    $(".billCompany").removeClass("hidden-xl-down");
                }
                else if (event.currentTarget.id=='faturaTipiKisisel')
                {
                    $(".billCompany").addClass("hidden-xl-down");
                }
            });

        },
        initAddCard: function () {

            $(".kart-mesaji .product").click(function () {
                var thisis = $(this);

                console.log(thisis);
                thisis.find(".addPostCard").trigger("click");
                thisis.find(".icheckbox_flat-green").toggleClass("checked");

            });

        },
        initOrderStatusControl: function () {




                $("#orderStatusControl").submit(function (e) {
                    e.preventDefault();

                    var thisis = $(this);

                    var data = new Object({});
                    data.action = 14;
                    data.orderNumber = $('#orderNumber').val();
                    data.email = $('#email').val();

                    $.ajax({
                        type: "POST",
                        url: '/api/ajax.php',
                        data: data,
                        success: function (returnData) {

                            console.log(returnData);

                            if (returnData) {
                                var tarih="";
                                    if (returnData.deliveryTime !== 0) {
                                        tarih='Teslim Tarihi : '+returnData.deliveryTime;
                                    }
                                $(".result").html(returnData.orderStatusText+' <br> Teslim Alan : '+returnData.deliveryPerson+' <br> '+tarih);

                            }
                            else {
                                console.log("Bir Hata Oluştu");
                            }


                        }, dataType: "json"
                    });
                });

        },


        init: function () {
            mobileMenu();
            mobileSearchInit();
            calendarInit();
            photoGalleryInit();
            initSlider();
            initTopFormSubmit($("form.search"));
            commonModule.initAliciBilgileri();
            commonModule.initGonderenBilgileri();
            commonModule.initDatePickerProductDetail();
            commonModule.initMasterImagesSlider();
            commonModule.initSelect2();
            commonModule.initIcheck();
            commonModule.initExtraProductAddButton();
            commonModule.initAddToCartSubmit();
            commonModule.initSaveAliciBilgileriSubmit();
            commonModule.initBackToTop();
            //commonModule.initSaveCardMessageSubmit();
            commonModule.initCustomerInfoSaveSubmit();
            commonModule.initBasketInfo();
            commonModule.initOrderInfo();
            commonModule.initExtraProductInformation();
            commonModule.initPostCardInformation();
            commonModule.initAddExtraProduct();
            commonModule.initPostCardMessage();
            commonModule.initPaymentType();
            commonModule.initCCPayment();
            commonModule.initBankPayment();
            commonModule.initAddCard();
            commonModule.initOrderStatusControl();
            commonModule.initBillingType();

            initStickyHeader();
            initFaturaGoster();
            initFaturaSirket();


        }
    };


})();

$(document).ready(function () {


    commonModule.init();
    //  $('input').on('ifChanged', function (event) {
    // $(event.target).trigger('change');
    // });
    $('#addToCart').validationEngine('attach', {
        autoHidePrompt: true,
        autoHideDelay: 3000,
        fadeDuration: 200,
        scrollOffset: 200
    });
    $("form#aliciBilgileriForm").validationEngine('attach', {
        autoHidePrompt: true,
        autoHideDelay: 3000,
        fadeDuration: 200,
        scrollOffset: 200
    });
    $("form#customerInfoSaveForm").validationEngine('attach', {
        autoHidePrompt: true,
        autoHideDelay: 3000,
        fadeDuration: 200,
        scrollOffset: 200
    });
    //$("form#cardMessageForm").validationEngine();
    //$("#my-menu").css('display','block');

});
